<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 5.91667C13.825 5.91667 16.6417 7.69167 18.0167 10.5C16.6417 13.3083 13.8333 15.0833 10.6667 15.0833C7.5 15.0833 4.69167 13.3083 3.31667 10.5C4.69167 7.69167 7.50833 5.91667 10.6667 5.91667ZM10.6667 4.25C6.5 4.25 2.94167 6.84167 1.5 10.5C2.94167 14.1583 6.5 16.75 10.6667 16.75C14.8333 16.75 18.3917 14.1583 19.8333 10.5C18.3917 6.84167 14.8333 4.25 10.6667 4.25ZM10.6667 8.41667C11.8167 8.41667 12.75 9.35 12.75 10.5C12.75 11.65 11.8167 12.5833 10.6667 12.5833C9.51667 12.5833 8.58333 11.65 8.58333 10.5C8.58333 9.35 9.51667 8.41667 10.6667 8.41667ZM10.6667 6.75C8.6 6.75 6.91667 8.43333 6.91667 10.5C6.91667 12.5667 8.6 14.25 10.6667 14.25C12.7333 14.25 14.4167 12.5667 14.4167 10.5C14.4167 8.43333 12.7333 6.75 10.6667 6.75Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcViews',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
